import React, { useEffect } from 'react'

const Company: React.FCX = (props) => {
  useEffect(() => {
    window.location.href = 'https://smooth.jp/company'
  }, [])

  return (
    <div className={`${props.className} page-wrapper`}>
    </div>
  )
}

export default Company
