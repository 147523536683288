import React from 'react'
import { Head } from 'src/components/layout/head'
import { DefaultLayout } from 'src/components/layout/default'
import Company from 'src/components/pages/Company'

const CompanyPage: React.FCX = () => {
  return (
    <div className="company-page">
      <Head title="会社概要 | 株式会社スムーズ" />
      <DefaultLayout>
        <Company />
      </DefaultLayout>
    </div>
  )
}

export default CompanyPage
